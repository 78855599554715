import * as React from 'react'
import Layout from '../components/Layout/index'

const IndexPage = () => {
    return (
        <Layout>
            <h1>willkommen zu mimomatosi</h1>
        </Layout>
    )
}

export default IndexPage
